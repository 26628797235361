

body, html {
  align-items: center;
  background: rgba(0, 0, 0,.7);
  justify-content: center;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  font-family: 'Questrial', sans-serif;
}

.name {
  font-size: 70px;
  margin-bottom: 0;
  text-align: center;
}

.tl {
  font-size: 70px;
  margin-top: 0;
  text-transform: lowercase;
  font-weight: bold;
}



